import { ReactComponent as QualityIcon } from 'assets/streamline-light/building-construction/renovation-improvements/renovation-4.svg';
import { ReactComponent as Star } from 'assets/streamline-light/social-medias-rewards-rating/rating/rating-star.svg';
import { easeQuadOut } from 'd3-ease';
import { interpolate } from 'd3-interpolate';
import { memo, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Animate } from 'react-move';
import { useParams } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';

import {
  Condition,
  Currency,
  DealType,
  PropertyCode,
  PropertyCondition,
  PropertyInput,
  PropertyQuality,
  Quality,
  UpdatePropertyInput,
  UserStatus,
} from '../../../../generated';
import {
  useLazyPropertyValuationQuery,
  usePropertyQuery,
  useUpdatePropertyMutation,
} from '../../../../services/graphql/enhanced';
import { device } from '../../../../style/theme';
import { Tooltip } from '../../../common/components/tooltip';
import {
  MainButton,
  SecondaryButton,
} from '../../../common/components/ui/buttons';
import Icon from '../../../common/components/ui/icon';
import {
  Headline2,
  ParagraphText,
} from '../../../common/components/ui/typography';
import {
  CONDITION_ELEMENTS,
  CONDITION_VALUES,
  QUALITY_VALUES,
} from '../../../common/constants/conditional-calculator';
import { useAppSelector } from '../../../common/hooks';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import {
  useEURtoCHF,
  useToLocalWithCurrency,
} from '../../../common/utils/convertCHF';
import { useCurrency } from '../../hooks/useCurrency';
import { calculateSquareMeterPrice } from '../../utils/calculate-square-meter-price';
import { parseBackToEnum } from '../../utils/parse-quality-condition';
import { percentageDifference } from '../../utils/percentage-difference';
import { sanitize } from '../../utils/sanitize';
import { SalePriceElement } from '../card/market-price-card/price-element';
import {
  ConditionCalculatorRatingRow,
  ConditionCalculatorRatingWrapper,
  ConditionCalculatorRow,
  ConditionCalculatorRowLeft,
  ConditionCalculatorRowRight,
  ConditionCalculatorStarWrapper,
  ConditionCalculatorWrapper,
  ConditionClarification,
  ConditionClarificationWrapper,
  ConditionRating,
} from './condition-calculator';

interface IConditions {
  [key: string]: number;
}
type QualityWithSignature =
  | (PropertyQuality & { [key: string]: Quality | string | null })
  | null;

type ConditionWithSignature =
  | (PropertyCondition & { [key: string]: Condition | string | null })
  | null;

const PriceCardsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 12px 0;
  @media ${device.tablet} {
    flex-direction: column;
  }
`;

const Title = styled(Headline2)`
  margin-bottom: 8px;
`;

const Content = styled(ParagraphText)``;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  @media ${device.tablet} {
    flex-direction: column;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin: 0 6px 0 0;
  flex: 0 1 auto;
  button {
    text-transform: uppercase;
  }
  @media ${device.tablet} {
    margin: 6px 0;
    flex-direction: column;
  }
`;

const TooltipParagraph = styled.p`
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.75px;
  text-align: center;
  color: ${(props) => props.theme.blue};
`;

const ValueCalculatorBase = (): JSX.Element => {
  const { t } = useTranslation();
  const [isPropertySaved, setPropertySaved] = useState(true);
  const userId = useAppSelector((state) => state.auth.user?._id);

  const themeContext = useContext(ThemeContext);
  const isMobileSize = useIsMobileSize();

  const { propertyId } = useParams<{ propertyId: string }>();

  const { currentValuation, property, countryCode, dealType, userStatus } =
    usePropertyQuery(
      {
        id: propertyId,
      },
      {
        selectFromResult: ({ data }) => {
          const {
            valuations,
            valuationsLastSync,
            propertyData,
            countryCode: propertyCountryCode,
            dealType: propertyDealType,
          } = data?.property ?? {};

          const propertyCurrentValuation = valuations?.find(
            (valuation) =>
              new Date(valuation.date).toDateString() ===
              new Date(valuationsLastSync).toDateString()
          );

          return {
            currentValuation: propertyCurrentValuation,
            property: propertyData,
            countryCode: propertyCountryCode ?? 'DE',
            dealType: propertyDealType ?? DealType.Sale,
            userStatus: data?.property?.userStatus,
          };
        },
      }
    );

  const priceCurrency = useCurrency();

  const [qualityConditions, setQualityCondition] = useState<IConditions>({});
  const [constructionConditions, setConstructionCondition] =
    useState<IConditions>({});

  const [conditionsChanged, setConditionsChanged] = useState<boolean>(false);
  const [isEmptyConditionsError, setIsEmptyConditionsError] =
    useState<boolean>(false);

  const triggerIsEmptyConditionsError = () => {
    setIsEmptyConditionsError(true);
    setTimeout(() => setIsEmptyConditionsError(false), 500);
  };

  const [
    valuatePropertyTrigger,
    {
      forecastSalePrice,
      forecastSalePriceRange,
      isLoading: isForecastValuationLoading,
      isFetching: isForecastValuationFetching,
    },
  ] = useLazyPropertyValuationQuery({
    selectFromResult: ({ data, isLoading, isFetching }) => ({
      forecastSalePrice: data?.propertyValuation?.[0]?.valuation?.salePrice,
      forecastSalePriceRange:
        data?.propertyValuation?.[0]?.valuation?.salePriceRange,
      isLoading,
      isFetching,
    }),
  });

  const [updatePropertyTrigger, { isLoading: isPropertyUpdateLoading }] =
    useUpdatePropertyMutation();

  const createPropertyForecastValuation = () => {
    if (!conditionsChanged) {
      triggerIsEmptyConditionsError();
      return;
    }

    const parsedQualityConditions: PropertyQuality & {
      [key: string]: Quality;
    } = {};

    Object.keys(qualityConditions).forEach((k) => {
      parsedQualityConditions[k] =
        parseBackToEnum(QUALITY_VALUES, qualityConditions[k]) ?? Quality.Simple;
    });

    const parsedConstructionConditions: PropertyCondition & {
      [key: string]: Condition;
    } = {};

    Object.keys(constructionConditions).forEach((k) => {
      parsedConstructionConditions[k] =
        parseBackToEnum(CONDITION_VALUES, constructionConditions[k]) ??
        Condition.RenovationNeeded;
    });

    const input = {
      ownerId: userId,
      dealType,
      countryCode,
      propertyData: sanitize({
        ...property,
        quality: {
          ...property?.quality,
          ...parsedQualityConditions,
        },
        condition: {
          ...property?.condition,
          ...parsedConstructionConditions,
        },
      }) as PropertyInput,
    };

    valuatePropertyTrigger({ input });
    setPropertySaved(false);
  };

  const updateProperty = async () => {
    const parsedQualityConditions: PropertyQuality & {
      [key: string]: Quality;
    } = {};

    Object.keys(qualityConditions).forEach((k) => {
      parsedQualityConditions[k] =
        parseBackToEnum(QUALITY_VALUES, qualityConditions[k]) ?? Quality.Simple;
    });

    const parsedConstructionConditions: PropertyCondition & {
      [key: string]: Condition;
    } = {};

    Object.keys(constructionConditions).forEach((k) => {
      parsedConstructionConditions[k] =
        parseBackToEnum(CONDITION_VALUES, constructionConditions[k]) ??
        Condition.RenovationNeeded;
    });

    const input = {
      _id: propertyId,
      propertyData: sanitize({
        quality: {
          ...property?.quality,
          ...parsedQualityConditions,
        },
        condition: {
          ...property?.condition,
          ...parsedConstructionConditions,
        },
      }),
    } as UpdatePropertyInput;

    await updatePropertyTrigger({ input });
    setConditionsChanged(false);
    setPropertySaved(true);
  };

  const getPropertyQuality = (type: string): number => {
    const quality =
      (property?.quality as QualityWithSignature)?.[type] ??
      property?.quality?.overall;
    const qualityValue = QUALITY_VALUES.find((v) => v.label === quality)?.value;

    return qualityValue ?? 0;
  };

  const setPropertyQuality = (name: string, value: number) => {
    const currentValue = qualityConditions[name] ?? getPropertyQuality(name);
    const newValue =
      currentValue === value
        ? value - 1
        : (value < currentValue && value - 1) || value;
    setQualityCondition((prevState) => ({ ...prevState, [name]: newValue }));
    setConditionsChanged(true);
  };

  const getPropertyCondition = (type: string): number => {
    const condition =
      (property?.condition as ConditionWithSignature)?.[type] ??
      property?.condition?.overall;
    const conditionValue = CONDITION_VALUES.find(
      (v) => v.label === condition
    )?.value;

    return conditionValue ?? 0;
  };

  const setPropertyCondition = (name: string, value: number) => {
    const currentValue =
      constructionConditions[name] ?? getPropertyCondition(name);
    const newValue =
      currentValue === value
        ? value - 1
        : (value < currentValue && value - 1) || value;
    setConstructionCondition((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
    setConditionsChanged(true);
  };

  const percentageChange = useMemo(
    () =>
      percentageDifference(
        currentValuation?.valuation.salePrice ?? 0,
        forecastSalePrice ?? 0,
        true
      ),
    [currentValuation, forecastSalePrice]
  );

  const [localForecastSalePrice, localForecastSalePriceCurrency] =
    useToLocalWithCurrency(forecastSalePrice || 0, Currency.Eur, true) as [
      string,
      string
    ];
  const [localSalePrice] = useToLocalWithCurrency(
    currentValuation?.valuation.salePrice || 0
  ) as [string, string];

  const chfForecastSalePrice = useEURtoCHF(forecastSalePrice || 0);
  const chfSalePrice = useEURtoCHF(currentValuation?.valuation.salePrice || 0);

  const chfForecastPriceRangeLower = useEURtoCHF(
    forecastSalePriceRange?.lower ?? 0
  );
  const chfForecastPriceRangeUpper = useEURtoCHF(
    forecastSalePriceRange?.upper ?? 0
  );

  const chfValuationPriceRangeLower = useEURtoCHF(
    currentValuation?.valuation.salePriceRange?.lower ?? 0
  );
  const chfValuationPriceRangeUpper = useEURtoCHF(
    currentValuation?.valuation.salePriceRange?.upper ?? 0
  );

  const calculatorContent = useMemo(() => {
    if (
      !!forecastSalePrice &&
      !isPropertySaved &&
      !isForecastValuationLoading &&
      !isForecastValuationFetching
    ) {
      return percentageChange > 0
        ? t(
            'valuation-overview.price-calculator.content.increase.price-currency',
            {
              price: localForecastSalePrice,
              percent: percentageChange,
              priceCurrency: localForecastSalePriceCurrency,
            }
          )
        : t(
            'valuation-overview.price-calculator.content.decrease.price-currency',
            {
              price: localForecastSalePrice,
              percent: Math.abs(percentageChange),
              priceCurrency: localForecastSalePriceCurrency,
            }
          );
    }

    return t('valuation.overview.price-calculator.content');
  }, [
    forecastSalePrice,
    isForecastValuationFetching,
    isForecastValuationLoading,
    isPropertySaved,
    percentageChange,
    localForecastSalePrice,
    localForecastSalePriceCurrency,
    t,
  ]);

  return (
    <>
      <Title content={t('valuation.overview.price-calculator.title')} />
      <Content content={calculatorContent} />
      <>
        <Animate
          show={
            !!forecastSalePrice &&
            !isPropertySaved &&
            !isForecastValuationLoading &&
            !isForecastValuationFetching
          }
          start={{
            opacity: 0,
          }}
          enter={{
            opacity: [1],
            timing: { duration: 665, ease: easeQuadOut },
          }}
          update={{
            opacity: [1],
            timing: { duration: 665, ease: easeQuadOut },
          }}
          leave={{
            opacity: [0],
            timing: { duration: 665, ease: easeQuadOut },
          }}
          interpolation={(begValue, endValue) =>
            interpolate(begValue, endValue)
          }
        >
          {(style) => (
            <PriceCardsWrapper style={style}>
              <SalePriceElement
                oldPrice={localSalePrice}
                salePrice={localForecastSalePrice}
                priceLabel="valuation-overview.price-calculator.market-price"
                priceChangePercentage={percentageChange}
                backgroundColor={
                  percentageChange >= 0
                    ? themeContext.paleGreen
                    : themeContext.paleRed
                }
                borderColor={
                  percentageChange >= 0
                    ? themeContext.lightGreen
                    : themeContext.red
                }
                priceChangePercentageColor={
                  percentageChange >= 0 ? themeContext.green : themeContext.red
                }
              />
              <SalePriceElement
                oldPrice={calculateSquareMeterPrice(
                  property?.livingArea ?? 0,
                  priceCurrency === ' CHF'
                    ? chfSalePrice
                    : currentValuation?.valuation.salePrice ?? 0
                )}
                salePrice={calculateSquareMeterPrice(
                  property?.livingArea ?? 0,
                  priceCurrency === ' CHF'
                    ? chfForecastSalePrice
                    : forecastSalePrice ?? 0
                )}
                priceLabel="valuation-overview.price-calculator.square-price"
                priceChangePercentage={percentageChange}
                backgroundColor={
                  percentageChange >= 0
                    ? themeContext.paleGreen
                    : themeContext.paleRed
                }
                borderColor={
                  percentageChange >= 0
                    ? themeContext.lightGreen
                    : themeContext.red
                }
                priceChangePercentageColor={
                  percentageChange >= 0 ? themeContext.green : themeContext.red
                }
              />
              <SalePriceElement
                oldPriceLow={
                  priceCurrency === ' CHF'
                    ? chfValuationPriceRangeLower
                    : currentValuation?.valuation.salePriceRange?.lower ?? 0
                }
                oldPriceHigh={
                  priceCurrency === ' CHF'
                    ? chfValuationPriceRangeUpper
                    : currentValuation?.valuation.salePriceRange?.upper ?? 0
                }
                priceRangeLow={
                  priceCurrency === ' CHF'
                    ? chfForecastPriceRangeLower
                    : forecastSalePriceRange?.lower ?? 0
                }
                priceRangeHigh={
                  priceCurrency === ' CHF'
                    ? chfForecastPriceRangeUpper
                    : forecastSalePriceRange?.upper ?? 0
                }
                priceLabel="valuation-overview.price-calculator.span-price"
                priceChangePercentage={percentageChange}
                backgroundColor={
                  percentageChange >= 0
                    ? themeContext.paleGreen
                    : themeContext.paleRed
                }
                borderColor={
                  percentageChange >= 0
                    ? themeContext.lightGreen
                    : themeContext.red
                }
                priceChangePercentageColor={
                  percentageChange >= 0 ? themeContext.green : themeContext.red
                }
              />
            </PriceCardsWrapper>
          )}
        </Animate>
      </>
      <ConditionCalculatorWrapper>
        <>
          {!isMobileSize && (
            <ConditionCalculatorRow>
              <ConditionCalculatorRowRight
                hasTopLeftBorderRadius
                hasTopRightBorderRadius
                borders="1px 1px 0 1px"
              >
                <ConditionCalculatorStarWrapper skipBorder>
                  <span>
                    {t('valuation-overview.price-calculator.quality')}
                  </span>
                </ConditionCalculatorStarWrapper>
                <ConditionCalculatorStarWrapper>
                  <span>
                    {t('valuation-overview.price-calculator.condition')}
                  </span>
                </ConditionCalculatorStarWrapper>
              </ConditionCalculatorRowRight>
            </ConditionCalculatorRow>
          )}
        </>
        <>
          {isMobileSize && (
            <ConditionClarification
              text={t(
                'valuation-overview.price-calculator.condition-clarification'
              )}
            />
          )}
        </>
        <>
          {property?.propertyType.code === PropertyCode.MultiFamilyHouse ? (
            <ConditionCalculatorRatingRow>
              <ConditionCalculatorRowLeft
                borders="1px 0 1px 1px"
                borderRadiuses="3px 0 0 3px"
                margin="0 0 -1px 0"
              >
                <Icon
                  style={{
                    margin: '0 12px 0 0',
                  }}
                  icon={QualityIcon}
                  height={24}
                  width={24}
                  color={themeContext.blue}
                />
                <span>{t('overall')}</span>
              </ConditionCalculatorRowLeft>
              <ConditionCalculatorRowRight>
                <ConditionCalculatorStarWrapper>
                  <>
                    {isMobileSize && (
                      <ConditionCalculatorRow mobileStart>
                        <span>
                          {t('valuation-overview.price-calculator.quality')}
                        </span>
                      </ConditionCalculatorRow>
                    )}
                  </>
                  <ConditionCalculatorRatingWrapper>
                    <ConditionRating
                      ratingStartsNumber={QUALITY_VALUES}
                      value={getPropertyQuality('overall')}
                      newValue={qualityConditions.overall}
                      type="overall"
                      icon={Star}
                      onChange={setPropertyQuality}
                    />
                  </ConditionCalculatorRatingWrapper>
                </ConditionCalculatorStarWrapper>
                <ConditionCalculatorStarWrapper>
                  <>
                    {isMobileSize && (
                      <ConditionCalculatorRow mobileStart>
                        <span>
                          {t('valuation-overview.price-calculator.condition')}
                        </span>
                      </ConditionCalculatorRow>
                    )}
                  </>
                  <ConditionCalculatorRatingWrapper>
                    <ConditionRating
                      ratingStartsNumber={CONDITION_VALUES}
                      value={getPropertyCondition('overall')}
                      newValue={constructionConditions.overall}
                      type="overall"
                      icon={Star}
                      onChange={setPropertyCondition}
                    />
                  </ConditionCalculatorRatingWrapper>
                </ConditionCalculatorStarWrapper>
              </ConditionCalculatorRowRight>
            </ConditionCalculatorRatingRow>
          ) : (
            CONDITION_ELEMENTS.filter((el) =>
              property?.propertyType.code === PropertyCode.Apartment
                ? el.value !== 'masonry'
                : true
            ).map((el, index) => (
              <ConditionCalculatorRatingRow key={`cc-row-${el.value}`}>
                <ConditionCalculatorRowLeft
                  borders="1px 0 1px 1px"
                  borderRadiuses={`${index === 0 ? '3px' : '0'} 0 0 ${
                    index ===
                    CONDITION_ELEMENTS.length -
                      (property?.propertyType.code === PropertyCode.House
                        ? 1
                        : 2)
                      ? '3px'
                      : '0'
                  }`}
                  margin="0 0 -1px 0"
                >
                  <Icon
                    style={{
                      margin: '0 12px 0 0',
                    }}
                    icon={el.icon}
                    height={24}
                    width={24}
                    color={themeContext.blue}
                  />
                  <span>{t(el.value)}</span>
                </ConditionCalculatorRowLeft>
                <ConditionCalculatorRowRight>
                  <ConditionCalculatorStarWrapper>
                    <>
                      {isMobileSize && (
                        <ConditionCalculatorRow mobileStart>
                          <span>
                            {t('valuation-overview.price-calculator.quality')}
                          </span>
                        </ConditionCalculatorRow>
                      )}
                    </>
                    <ConditionCalculatorRatingWrapper>
                      <ConditionRating
                        ratingStartsNumber={QUALITY_VALUES}
                        value={getPropertyQuality(el.value)}
                        newValue={qualityConditions[el.value]}
                        type={el.value}
                        icon={Star}
                        onChange={setPropertyQuality}
                      />
                    </ConditionCalculatorRatingWrapper>
                  </ConditionCalculatorStarWrapper>
                  <ConditionCalculatorStarWrapper>
                    <>
                      {isMobileSize && (
                        <ConditionCalculatorRow mobileStart>
                          <span>
                            {t('valuation-overview.price-calculator.condition')}
                          </span>
                        </ConditionCalculatorRow>
                      )}
                    </>
                    <ConditionCalculatorRatingWrapper>
                      <ConditionRating
                        ratingStartsNumber={CONDITION_VALUES}
                        value={getPropertyCondition(el.value)}
                        newValue={constructionConditions[el.value]}
                        type={el.value}
                        icon={Star}
                        onChange={setPropertyCondition}
                      />
                    </ConditionCalculatorRatingWrapper>
                  </ConditionCalculatorStarWrapper>
                </ConditionCalculatorRowRight>
              </ConditionCalculatorRatingRow>
            ))
          )}
        </>
        <>
          {!isMobileSize && (
            <ConditionCalculatorRow>
              <ConditionCalculatorRowRight hasBottomRightBorderRadius>
                <ConditionClarificationWrapper>
                  <ConditionClarification
                    text={t(
                      'valuation-overview.price-calculator.condition-clarification'
                    )}
                    isError={isEmptyConditionsError}
                  />
                </ConditionClarificationWrapper>
              </ConditionCalculatorRowRight>
            </ConditionCalculatorRow>
          )}
        </>
      </ConditionCalculatorWrapper>

      <>
        <ButtonsWrapper>
          <ButtonWrapper>
            {userStatus !== UserStatus.Spectator ? (
              <MainButton
                centered
                label={t(
                  'valuation-overview.price-calculator.calculate-button'
                )}
                onClick={createPropertyForecastValuation}
                loader
                isDisabled={
                  isForecastValuationFetching || isForecastValuationLoading
                }
                isLoading={
                  isForecastValuationFetching || isForecastValuationLoading
                }
              />
            ) : (
              <Tooltip
                id={'valuation-overview.price-calculator.submit'}
                content={
                  <MainButton
                    centered
                    label={t(
                      'valuation-overview.price-calculator.calculate-button'
                    )}
                    isDisabled
                  />
                }
              >
                <TooltipParagraph>
                  {t('property.spectator.not-allowed.tooltip')}
                </TooltipParagraph>
              </Tooltip>
            )}
          </ButtonWrapper>
          {!isPropertySaved && userStatus !== UserStatus.Spectator && (
            <ButtonWrapper>
              <SecondaryButton
                label={t('valuation-overview.price-calculator.save-button')}
                onClick={updateProperty}
                loader
                isDisabled={isPropertyUpdateLoading}
                isLoading={isPropertyUpdateLoading}
              />
            </ButtonWrapper>
          )}
        </ButtonsWrapper>
      </>
    </>
  );
};

const ValueCalculator = memo(ValueCalculatorBase);

export { ValueCalculator };
