import { FC, useCallback, useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ButtonWithTooltip } from '../../../common/components/ui/buttons/button-with-tooltip';
import {
  ActionButton,
  IconContainer,
  IconLabel,
  Row,
} from '../../../landing/components/buyer-lp/suggested-properties/components/suggested-properties-styles';
import { ReactComponent as PropertyActions } from '../../../../assets/streamline-light/interface-essential/menu/navigation-menu-horizontal-filled.svg';
import { hexToRGB } from '../../../common/utils/hex-to-rgb';
import { ToolTipBasicContent } from '../../../common/components/tooltip/TooltipBasicContent';
import Icon from '../../../common/components/ui/icon';
import { ReactComponent as Heart } from '../../../../assets/streamline-light/social-medias-rewards-rating/love-it/love-it.svg';
import { ReactComponent as SmileSad } from '../../../../assets/streamline-light/messages-chat-smileys/smileys/smiley-sad-1.svg';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import {
  useLikePropertyMutation,
  useMarkPropertyUnsuitableMutation,
} from '../../../../services/graphql/enhanced';
import { removeFromSuggestedProperties } from '../../redux/suggestedPropertiesSlice';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';

interface IProps {
  propertyId: string;
  propertyPrice: number;
  isLiked: boolean;
  isUnsuitable: boolean;
}

export const SuggestedPropertyActionsButton: FC<IProps> = ({
  propertyId,
  propertyPrice,
  isLiked,
  isUnsuitable,
}) => {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();
  const { searchProfileId } = useParams<{ searchProfileId: string }>();
  const dispatch = useAppDispatch();
  const [isLikeDisabled, setIsLikeDisabled] = useState<boolean>(isLiked);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(true);

  const userInfo = useAppSelector((state) => state.auth.user);

  const [likeProperty] = useLikePropertyMutation();
  const [markPropertyUnsuitable] = useMarkPropertyUnsuitableMutation();

  const onClickLikeProperty = useCallback(async () => {
    try {
      if (userInfo?._id) {
        await likeProperty({
          propertyId,
          propertyPrice,
          searchProfileId,
        });
        setIsLikeDisabled(true);
        setIsTooltipVisible(false);
      }
    } catch (error) {
      console.error(error);
    }
  }, [likeProperty, propertyId, propertyPrice, searchProfileId, userInfo?._id]);

  const onClickMarkPropertyUnsuitable = useCallback(async () => {
    try {
      if (userInfo?._id) {
        await markPropertyUnsuitable({
          searchProfileId,
          propertyId,
        });
        dispatch(removeFromSuggestedProperties(propertyId));
      }
    } catch (error) {
      console.error(error);
    }
  }, [
    dispatch,
    markPropertyUnsuitable,
    propertyId,
    searchProfileId,
    userInfo?._id,
  ]);

  return (
    <ButtonWithTooltip
      id={`actions-tooltip-${propertyId}`}
      stayOnHover
      isVisible={isTooltipVisible}
      Button={
        <ActionButton
          label={''}
          onClick={() => null}
          icon={PropertyActions}
          color={themeContext.blue}
          borderColor={themeContext.ctaDisabledBackground}
          width={40}
          iconStyle={{
            margin: 0,
            padding: '2px',
          }}
        />
      }
      offset={{ left: isMobileSize ? -100 : -50 }}
      tipLeftPosition={isMobileSize ? 38 : 88}
      Tooltip={
        <ToolTipBasicContent
          content={
            <>
              <IconContainer
                onClick={isLikeDisabled ? undefined : onClickLikeProperty}
                disabled={isLikeDisabled}
              >
                <Row margin="0 0 4px 0" alignItems="center">
                  <Icon
                    icon={Heart}
                    style={{
                      marginRight: '8px',
                    }}
                    color={hexToRGB(themeContext.blue, 0.5)}
                  />
                  <IconLabel>
                    {t(
                      isLikeDisabled
                        ? 'search-profile.suggested-properties.actions.added-to-favourites'
                        : 'search-profile.suggested-properties.actions.add-to-favourites'
                    ).toUpperCase()}
                  </IconLabel>
                </Row>
              </IconContainer>
              <IconContainer
                onClick={
                  isUnsuitable ? undefined : onClickMarkPropertyUnsuitable
                }
                disabled={isUnsuitable}
              >
                <Row margin="12px 0 4px 0" alignItems="center">
                  <Icon
                    icon={SmileSad}
                    style={{
                      marginRight: '8px',
                    }}
                    color={hexToRGB(themeContext.blue, 0.5)}
                  />
                  <IconLabel>
                    {t(
                      'search-profile.suggested-properties.actions.mark-unsuitable'
                    ).toUpperCase()}
                  </IconLabel>
                </Row>
              </IconContainer>
            </>
          }
        />
      }
    />
  );
};
