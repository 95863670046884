import { FC, useCallback, useContext, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  ActionButton,
  IconLabel,
  Row,
} from '../../../landing/components/buyer-lp/suggested-properties/components/suggested-properties-styles';
// import { ReactComponent as CalendarCheck } from '../../../../assets/streamline-light/interface-essential/date-calendar/calendar-check-1.svg';
import { ReactComponent as View } from '../../../../assets/streamline-light/interface-essential/view/view.svg';
import { hexToRGB } from '../../../common/utils/hex-to-rgb';
import { useIsMobileSize } from '../../../common/hooks/useIsMobileSize';
import { SuggestedPropertyActionsButton } from './suggested-property-actions-button';
import { ReactComponent as BrokenHeart } from '../../../../assets/streamline-light/social-medias-rewards-rating/love-it/love-it-break.svg';
import { ReactComponent as CommonFileText } from 'assets/streamline-light/files-folders/common-files/common-file-text.svg';
import { ReactComponent as Finance } from 'assets/streamline-light/money-payments-finance/money/accounting-bill.svg';

import {
  clearFavouritePropertiesCache,
  removeFromFavourites,
  setInitialLoader,
} from '../../redux/favouritePropertiesSlice';
import { useAppSelector } from '../../../common/hooks';
import { useDislikePropertyMutation } from '../../../../services/graphql/enhanced';
import {
  CountryCodeFromVonPollDbEnum,
  FinancingType,
} from '../../../../generated';
import { ButtonWithTooltip } from 'modules/common/components/ui/buttons/button-with-tooltip';
import { setSelectedTab } from 'modules/property/redux/dynamicMapUtilsSlice';
import { siteMap } from 'routes/site-map';
import { useGetCountry } from 'modules/localization/get-country';
import { ToolTipBasicContent } from 'modules/common/components/tooltip/TooltipBasicContent';
import { SecondaryButton } from 'modules/common/components/ui/buttons';
import { useOpenFinancing } from 'modules/forms/form-financing/hooks/callback-hooks/useOpenFinancing';
import { setSuggestionProperty } from 'modules/forms/form-financing/redux/financingSlice';
import { setMarketingParameters } from 'modules/auth/redux/authSlice';
import { device } from 'style/theme';

const RequestExposeButton = styled(SecondaryButton)<{ disabled?: boolean }>`
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  width: 40px;
  padding: 6px;
  margin-left: 12px;
  border-radius: 3px;
  border-width: 1px;
`;
interface IProps {
  propertyId: string;
  propertyPrice: number;
  isListView?: boolean;
  detailsUrl: string;
  isLiked: boolean;
  isUnsuitable: boolean;
  isFavourite?: boolean;
  isRequestedForExpose?: boolean;
  countryCode?: CountryCodeFromVonPollDbEnum | null;
  isUnlocked?: boolean;
  id: number;
  onClickOpenOverlay?: () => void;
}

const SuggestedPropertyActionPanel: FC<IProps> = ({
  propertyId,
  propertyPrice,
  detailsUrl,
  isLiked,
  isUnsuitable,
  isFavourite = false,
  id,
  isUnlocked = false,
  countryCode,
  onClickOpenOverlay,
  isRequestedForExpose = false,
  isListView,
}) => {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { searchProfileId } = useParams<{ searchProfileId: string }>();
  const isGermany = countryCode === CountryCodeFromVonPollDbEnum.Deu;
  const { language, getCountry } = useGetCountry();
  const { openFinancing } = useOpenFinancing({});

  // const { isDeAtCh } = getCountry(countryCode || 'DE');
  // const userId = useAppSelector((state) => state.auth.user?._id);

  const isMobileSize = useIsMobileSize();
  const userInfo = useAppSelector((state) => state.auth.user);

  const [dislikeProperty] = useDislikePropertyMutation();

  const onClickPropertyDetails = useCallback(() => {
    if (detailsUrl) {
      window.open(detailsUrl, '_blank');
    }
  }, [detailsUrl]);

  const onClickDislikeProperty = useCallback(async () => {
    try {
      if (userInfo?._id && propertyId) {
        dispatch(setInitialLoader(true));
        await dislikeProperty({ searchProfileId, propertyId });
        batch(() => {
          dispatch(clearFavouritePropertiesCache());
          dispatch(removeFromFavourites(propertyId));
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setInitialLoader(false));
    }
  }, [dislikeProperty, dispatch, propertyId, searchProfileId, userInfo?._id]);

  // const handleOpenTimifyModal = () => {
  //   dispatch(toggleTimifyModal(true));
  //   dispatch(
  //     setPropertyMeta({
  //       vonpollPropertyId: id.toString(),
  //       vonpollUserId: userId || '',
  //     })
  //   );
  // };

  const onClickRequestExpose = useCallback(() => {
    if (isUnlocked && onClickOpenOverlay) {
      dispatch(setSelectedTab(2));
      onClickOpenOverlay();
    } else {
      window.open(
        isGermany
          ? `${siteMap.RequestExposePage.pathWithoutParams}/${propertyId}?searchProfileId=${searchProfileId}&propertyId=${id}`
          : `https://www.von-poll.com/${language}/expose/immobilie/${id}`,
        '_blank'
      );
    }
  }, [
    dispatch,
    id,
    isGermany,
    // isDeAtCh,
    isUnlocked,
    language,
    onClickOpenOverlay,
    propertyId,
    searchProfileId,
  ]);

  const onClickFinancing = () => {
    openFinancing(FinancingType.Financing);
    dispatch(
      setSuggestionProperty({ propertyId: id, purchasePrice: propertyPrice })
    );
    const utmParams = `utm_source=kundenportal&utm_medium=suchprofil&utm_campaign=online-expose-freigabe&property_id=${propertyId}`;
    const href = window?.location?.href;
    const markParams = href.includes('?')
      ? `${href}&${utmParams}`
      : `${href}?${utmParams}`;
    dispatch(setMarketingParameters(markParams));
  };

  return (
    <>
      <Row alignItems={'center'} justifyContent={'center'}>
        <Row alignItems={'baseline'}>
          {/* <ActionButton */}
          {/*  label={''} */}
          {/*  onClick={handleOpenTimifyModal} */}
          {/*  icon={CalendarCheck} */}
          {/*  color={themeContext.blue} */}
          {/*  borderColor={themeContext.blue} */}
          {/*  width={40} */}
          {/*  margin={'0 12px'} */}
          {/*  iconStyle={{ */}
          {/*    margin: 0, */}
          {/*    padding: '2px', */}
          {/*  }} */}
          {/* /> */}

          {isFavourite ? (
            <ActionButton
              label={''}
              onClick={onClickDislikeProperty}
              icon={BrokenHeart}
              color={themeContext.blue}
              borderColor={themeContext.blue}
              width={40}
              iconStyle={{
                margin: 0,
                padding: '3px',
              }}
            />
          ) : (
            <SuggestedPropertyActionsButton
              propertyPrice={propertyPrice}
              propertyId={propertyId}
              isLiked={isLiked}
              isUnsuitable={isUnsuitable}
            />
          )}

          <ActionButton
            label={''}
            width={40}
            onClick={onClickFinancing}
            icon={Finance}
            color={themeContext.blue}
            borderColor={themeContext.blue}
            margin={'0 0 0 12px'}
            iconStyle={{
              margin: '0',
              padding: '3px',
            }}
          />

          {isRequestedForExpose && (
            <ButtonWithTooltip
              id={`expose-tooltip-${propertyId}`}
              stayOnHover
              Button={
                <RequestExposeButton
                  label={''}
                  icon={CommonFileText}
                  iconStyle={{ padding: '3px', margin: 0 }}
                  onClick={onClickRequestExpose}
                  disabled={!isUnlocked}
                  color={themeContext.ctaBlue}
                  borderColor={themeContext.ctaBlue}
                />
              }
              Tooltip={
                isUnlocked ? null : (
                  <ToolTipBasicContent
                    content={
                      <IconLabel>
                        {t(
                          'search-profile.suggested-properties.expose-requested.tooltip'
                        )}
                      </IconLabel>
                    }
                  />
                )
              }
            />
          )}
        </Row>
        <ActionButton
          label={''}
          width={40}
          onClick={onClickPropertyDetails}
          icon={View}
          color={themeContext.blue}
          borderColor={themeContext.ctaDisabledBackground}
          margin={'0 0 0 12px'}
          iconStyle={{
            margin: '0',
            padding: '3px',
          }}
        />
      </Row>
    </>
  );
};

export default SuggestedPropertyActionPanel;
