import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { memo, useContext, useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { DefaultTheme, ThemeContext } from 'styled-components';

import { hexToRGB } from '../../../../common/utils/hex-to-rgb';
import { plugin } from '../../chart/background-plugin';

Chart.register([annotationPlugin]);

interface IProps {
  labels: string[];
  data: unknown;
  id: string;
}

interface ITooltipData {
  chart: typeof Chart;
  label: string;
  parsed: unknown;
  raw: unknown;
  formattedValue: string;
  dataset: unknown;
  datasetIndex: number;
  dataIndex: number;
  element: Element;
}

const buildDatasets = (
  themeContext: DefaultTheme,
  labels: string[],
  data: unknown,
  currentYearQuarterIndex: number
) => ({
  labels,
  datasets: [
    {
      labels,
      data,
      fill: false,
      borderColor: themeContext.blue,
      backgroundColor: themeContext.blue,
      pointerStyle: 'circle',
      pointRadius: 3,
      lineTension: 0,
      borderCapStyle: 'butt',
      borderWidth: 1,
      borderJoinStyle: 'miter',
      pointBorderColor: (context: ITooltipData) => {
        const index = context.dataIndex;
        if (index < currentYearQuarterIndex) {
          return themeContext.blue;
        }
        return themeContext.ctaBlue;
      },
      pointBackgroundColor: (context: ITooltipData) => {
        const index = context.dataIndex;
        if (index < currentYearQuarterIndex) {
          return themeContext.blue;
        }
        return themeContext.ctaBlue;
      },
      pointBorderWidth: 1,
      pointHoverRadius: 3,
      pointHoverBackgroundColor: themeContext.blue,
      pointHoverBorderColor: themeContext.blue,
      pointHoverBorderWidth: 6,
      pointHitRadius: 3,
    },
  ],
});

const TimeOnMarketLineChartBase = ({
  labels,
  data,
  id,
}: IProps): JSX.Element => {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();

  const currentYearQuarterIndex = useMemo(() => {
    return (labels || []).findIndex((item) =>
      item.includes(`${new Date().getFullYear()}`.slice(2))
    );
  }, [labels]);

  const chartData = useMemo(
    () => buildDatasets(themeContext, labels, data, currentYearQuarterIndex),
    [data, labels, themeContext, currentYearQuarterIndex]
  );

  const options = useMemo(
    () => ({
      maintainAspectRatio: true,
      interaction: {
        intersect: false,
        mode: 'point',
      },
      plugins: {
        legend: {
          display: false,
        },
        annotation: {
          annotations: {
            box1: {
              drawTime: 'afterDatasetsDraw',
              type: 'box',
              xMin: currentYearQuarterIndex,
              xMax: labels.length,
              backgroundColor: `${hexToRGB('#4e73f5', 0.2)}`,
            },
            line1: {
              type: 'line',
              xMin: currentYearQuarterIndex,
              xMax: currentYearQuarterIndex,
              borderColor: '#00305e',
              borderWidth: 2,
            },
          },
        },
        tooltip: {
          enabled: true,
          displayColors: false,
          backgroundColor: '#fff',
          titleColor: hexToRGB(themeContext.blue, 0.8),
          titleFont: {
            family: 'Roboto',
            size: 12,
            weight: '900',
            lineHeight: 1.67,
          },
          titleAlign: 'center',
          titleMarginBottom: 4,
          bodyColor: themeContext.blue,
          bodyFont: {
            family: 'Source Serif Pro',
            size: 16,
            weight: '600',
            lineHeight: 1.25,
          },
          bodyAlign: 'center',
          footerColor: hexToRGB(themeContext.blue, 0.5),
          footerFont: {
            family: 'Roboto',
            size: 10,
            weight: '900',
            lineHeight: 1.6,
          },
          footerAlign: 'center',
          footerMarginTop: 2,
          padding: {
            left: 20,
            right: 20,
            top: 8,
            bottom: 8,
          },
          cornerRadius: 3,
          borderColor: 'rgb(217, 217, 217)',
          borderWidth: 1,
          caretSize: 10,
          caretPadding: 8,
          usePointStyle: true,
          callbacks: {
            labelPointStyle: () => {
              return {
                pointStyle: 'triangle',
                rotation: 1,
              };
            },
            title: (context: [ITooltipData]) => {
              const { label } = context[0];
              if (!label) {
                return '';
              }
              return label;
            },
            label: (context: ITooltipData) => {
              return `${context.formattedValue} ${t('chart.tooltip.days')}`;
            },
            footer: () => {
              return t('chart.days-on-market.footer');
            },
          },
        },
      },
      chartArea: {
        backgroundColor: hexToRGB('#4e73f5', 0.05),
        step: 1,
      },
      scales: {
        x: {
          beginAtZero: false,
          offset: true,
          grid: {
            display: true,
            drawTicks: false,
            drawOnChartArea: false,
          },
          ticks: {
            major: {
              enabled: true,
            },
            maxRotation: 0,
            align: 'center',
            crossAlign: 'near',
            includeBounds: false,
            labelOffset: 0,
            padding: 8,
            color: hexToRGB(themeContext.blue, 0.3),
            font: {
              family: 'Roboto',
              size: 10,
              weight: '900',
              lineHeight: 1.6,
            },
            callback(value: number) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              // eslint-disable-next-line react/no-this-in-sfc
              const label: string = this.getLabelForValue(value);
              if (!label) {
                return '';
              }
              return label;
            },
          },
        },
        y: {
          beginAtZero: false,
          offset: true,
          grid: {
            drawBorder: true,
            borderDash: [2, 1],
            zeroLineColor: 'red',
            drawTicks: false,
          },
          ticks: {
            padding: 10,
            maxTicksLimit: 5,
            color: themeContext.blue,
            font: {
              family: 'Roboto',
              size: 12,
              weight: 'bold',
              lineHeight: 1.33,
            },
            callback(value: number) {
              return `${value.toFixed(2).replace(/[,.]00$/, '')} ${t(
                'chart.tooltip.days'
              )}`;
            },
          },
        },
      },
      layout: {
        padding: {
          left: 0,
        },
      },
    }),
    [t, themeContext.blue]
  );

  return <Line data={chartData} options={options} plugins={[plugin]} id={id} />;
};

const TimeOnMarketLineChart = memo(TimeOnMarketLineChartBase);

export { TimeOnMarketLineChart };
